import React from 'react';
import PropTypes from 'prop-types';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import {
  UNMASK_DOB_CONTEXT_LYFT,
  UNMASK_DOB_CONTEXT_TEST,
} from '../../../../constants';
import { formatCountry } from '../lib/screenings';

// Check hardcoded unmask situations (EINTSC-2079)
export const checkContextForUnmask = report => {
  // check if 'report' and 'geos' exist, to avoid TypeError
  if (report && report.geos) {
    return report.geos.some(
      geo =>
        geo.id === UNMASK_DOB_CONTEXT_LYFT.geo_id ||
        geo.id === UNMASK_DOB_CONTEXT_TEST.geo_id,
    );
  }
  return false;
};

// Unmask DOB if desired (Masked by Default)
export const getUnmaskedDobOrDefaultMasked = (dob, unmask) => {
  if (unmask) {
    return i18n.getLocalizedShortDateFormat(dob);
  }
  return i18n.getLocalizedDateOfBirth(dob);
};

const idData = regionalData => {
  const idTypes = [
    'passport_number',
    'national_id_number',
    'drivers_license_number',
  ];
  return (regionalData ?? []).find(rd => {
    return idTypes.includes(rd.name);
  });
};

export const idType = regionalData => {
  return regionalData?.length > 0
    ? idData(regionalData)?.name.replace('_number', '')
    : '';
};

export const idValue = regionalData => {
  return idData(regionalData)?.value;
};

const InternationalProfile = ({ report, pdf }) => {
  return (
    <M.Grid>
      <M.GridRow className='mb-md-4'>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.firstName' />
          </span>
          <br />
          {report.candidate.firstName}
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.middleName' />
          </span>
          <br />
          {report.candidate.middleName || '-'}
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.lastName' />
          </span>
          <br />
          {report.candidate.lastName}
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.dob' />
          </span>
          <br />
          {getUnmaskedDobOrDefaultMasked(
            report?.candidate?.dob,
            checkContextForUnmask(report),
          )}
        </M.GridCol>
      </M.GridRow>

      <M.GridRow className='mb-md-4'>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.email' />
          </span>
          <br />
          <div className='text-truncate' title={report.candidate.email}>
            {report.candidate.email}
          </div>
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.citizenship' />
          </span>
          <br />
          <div className='text-truncate'>
            {formatCountry(report.candidate.citizenship)}
          </div>
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.idType' />
          </span>
          <br />
          <div className='text-truncate'>
            <T
              value={`labels.idTypes.${idType(
                report?.candidate?.regionalData,
              )}`}
            />
          </div>
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T
              value={`labels.idTypes.${idType(
                report?.candidate?.regionalData,
              )}`}
            />
          </span>
          <br />
          <div className='text-truncate'>
            {idValue(report?.candidate?.regionalData)}
          </div>
        </M.GridCol>
      </M.GridRow>

      <M.GridRow className='mb-md-4'>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.createdAt' />
          </span>
          <br />
          {i18n.getLocalizedShortDateTimeFormatWithTz(report.createdAt, pdf)}
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.completedAt' />
          </span>
          <br />
          {report.completedAt
            ? i18n.getLocalizedShortDateTimeFormatWithTz(
                report.completedAt,
                pdf,
              )
            : '-'}
        </M.GridCol>
        {report.revisedAt && (
          <M.GridCol md={3} className='mb-3 mb-md-0'>
            <span className='text-very-muted'>
              <T value='labels.revisedAt' />
            </span>
            <br />
            {i18n.getLocalizedShortDateTimeFormatWithTz(report.revisedAt, pdf)}
          </M.GridCol>
        )}
      </M.GridRow>
    </M.Grid>
  );
};

InternationalProfile.propTypes = {
  report: PropTypes.object.isRequired,
  pdf: PropTypes.bool,
};

InternationalProfile.defaultProps = {
  pdf: false,
};

export default InternationalProfile;

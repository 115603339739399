import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import Typography2024Hack from './Typography2024Hack';

interface UnorderedListProps {
  textKeys: string[];
}

export const UnorderedList: React.FC<UnorderedListProps> = ({ textKeys }) => {
  return (
    <M.UnorderedList style={{ listStyleType: 'disc' }}>
      {textKeys.map(key => {
        return (
          <M.ListItem key={key}>
            <p
              style={{
                ...Typography2024Hack.p3,
              }}
              className='p3'
            >
              {i18n.getStr(key)}
            </p>
          </M.ListItem>
        );
      })}
    </M.UnorderedList>
  );
};

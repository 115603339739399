import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import Panel from '../../../Panels';
import { formatDateWithTimezone } from '../../../../lib/helpers';
import styles from './DrugAlcoholClearinghouse.module.css';
import withNonCanceledSearch from './WithNonCanceledSearch';
import { DrugAlcoholClearinghouseObject } from '../../../../types/report';

interface DrugAlcoholClearinghouseProps {
  search: DrugAlcoholClearinghouseObject;
}
const DrugAlcoholClearinghouse = ({
  search,
}: DrugAlcoholClearinghouseProps) => (
  <Panel
    data-testid='drug-alcohol-clearinghouse'
    title={i18n.getStr('components.drugAlcoholClearinghouse.title')}
    status={search.status}
  >
    {search.completedAt && (
      <div>
        <T value='components.drugAlcoholClearinghouse.description' />
        <span className='text-very-muted pl-2'>
          {formatDateWithTimezone(search.completedAt)}
        </span>
      </div>
    )}
    <M.Grid>
      {search.queryResult && (
        <M.GridRow className='mt-4 md-4 mb-2'>
          <M.GridCol md={3} className='text-very-muted mb-3 mb-md-0 pl-5'>
            <T value='components.drugAlcoholClearinghouse.queryResult' />
          </M.GridCol>
          <M.GridCol md={9} className='mb-3 mb-md-0'>
            {search.queryResult}
          </M.GridCol>
        </M.GridRow>
      )}

      {search.queryDetailsDocument && (
        <M.GridRow className='mb-md-4'>
          <M.GridCol md={3} className='text-very-muted mb-3 mb-md-0 pl-5'>
            <T value='components.drugAlcoholClearinghouse.queryDetails' />
          </M.GridCol>
          <M.GridCol md={9} className='mb-3 mb-md-0'>
            <a
              href={search.queryDetailsDocument.downloadUri}
              target='_blank'
              rel='noreferrer'
            >
              {search.queryDetailsDocument.filename}
            </a>
          </M.GridCol>
        </M.GridRow>
      )}
    </M.Grid>

    <div className={styles.styled} data-testid='disclaimer'>
      {i18n.renderHTML('components.drugAlcoholClearinghouse.disclaimer')}
    </div>
  </Panel>
);

DrugAlcoholClearinghouse.propTypes = {
  search: PropTypes.object,
};

DrugAlcoholClearinghouse.defaultProps = {
  search: {},
};

// @ts-ignore
export default withNonCanceledSearch(DrugAlcoholClearinghouse);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { daysSinceCreation } from './Reports/Report/lib/incidents';

const StyledBanner = styled.div`
  background-color: ${colors.cautionYellow60};
  color: ${colors.coolBlack100};
  padding: 5px 0px;
  text-align: center;

  p {
    font-weight: bold;
    margin-bottom: 0px;
  }
`;

export type Incident = {
  name: string;
  components: { name: string }[];
};

export interface ApiStatusBannerProps {
  incidents: Incident[];
  customIncidentContentKeys: string[];
  createdAt?: string;
  candidateFirstName?: string;
}

const ApiStatusBanner = ({
  customIncidentContentKeys,
  incidents,
  createdAt,
  candidateFirstName,
}: ApiStatusBannerProps) => {
  const daysSinceReport = createdAt ? daysSinceCreation(createdAt) : 0;

  const hasIncident = incidents?.length;
  const hasMultipleIncidents = incidents?.length > 1;

  const shouldShowBanner = hasIncident || customIncidentContentKeys.length > 0;

  if (!shouldShowBanner) {
    return <></>;
  }

  return (
    <StyledBanner>
      {customIncidentContentKeys.map(customIncident => (
        <p key={customIncident} data-testid='la-incident'>
          {i18n.renderHTML(customIncident, {
            daysSinceCreation: daysSinceReport,
            candidateName: candidateFirstName,
          })}
        </p>
      ))}
      {hasIncident === 1 && (
        <p data-testid='single-incident'>
          {i18n.renderHTML(
            'components.ApiStatusBanner.singleIncident',
            incidents[0].name,
          )}
        </p>
      )}
      {hasMultipleIncidents && (
        <p data-testid='multiple-incidents'>
          {i18n.renderHTML('components.ApiStatusBanner.multipleIncident')}
        </p>
      )}
    </StyledBanner>
  );
};

ApiStatusBanner.propTypes = {
  incidents: PropTypes.array,
  customIncidentContentKeys: PropTypes.arrayOf(PropTypes.string),
};

ApiStatusBanner.defaultProps = {
  incidents: [],
  customIncidentContentKeys: [],
};

export default ApiStatusBanner;

import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Type, TypeParams } from './Type';

interface IconProps {
  type?: Type;
}

export const Icon: React.FC<IconProps> = ({ type }) => {
  const { color: typeColor, icon: typeIcon } = TypeParams[type || Type.Default];
  const color = typeIcon ? typeColor : 'none';
  const icon = typeIcon || 'CheckmarkFilled';

  return (
    <M.Icon
      icon={icon}
      style={{ alignSelf: 'center', fill: color, height: 32, width: 32 }}
    />
  );
};

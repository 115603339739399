import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import Faq from 'components/Faq';
import I18nLink from 'components/I18nLink/I18nLink';
import Panel from '../../Panels';
import ClearNotice from './Notices/ClearNotice';
import CompletedNotice from './Notices/CompletedNotice';
import DisputeNotice from './Notices/DisputeNotice';
import ReportInformation from './Information';
import PendingNotice from './Notices/PendingNotice';
import SuspendedNotice from './Notices/SuspendedNotice';
import CanceledOnHoldNotice from './Notices/CanceledOnHoldNotice';
import StatementOfDispute from './StatementOfDispute';
import Verifications from './Verifications';
import ViewTypeToggle from './ViewTypeToggle';
import CandidateActionWorkflowCards from './CandidateActionWorkflowCards';
import CriminalScreenings from './CriminalScreenings';
import ClearinghouseReport from './ClearinghouseReport';
import CreditReport from './Screenings/CreditReport';
import DrugAlcoholClearinghouse from './Screenings/DrugAlcoholClearinghouse';
import DrugScreening from './Screenings/DrugScreening';
import {
  hasProLicenseVerificationManualEntries,
  ProLicenseVerification,
} from './Screenings/ProLicenseVerification';
import ProLicenseVerificationManualEntries from './Screenings/ProLicenseVerificationManualEntries';
import DrugV2HealthScreening from './Screenings/DrugV2HealthScreening';
import OccupationalHealthScreening from './Screenings/OccupationalHealthScreening';
import CommonQuestions from './Screenings/CommonQuestions';
import Notice from './Notice';
import { isStatusComplete, isStatusProcessing } from './lib/status';
import {
  CANCELED_STATUS,
  CLEAR_STATUS,
  CONSIDER_STATUS,
  DISPUTE_STATUS,
  PENDING_STATUS,
  ReportView,
  SUSPENDED_STATUS,
} from './lib/constants';

import {
  hasEScreenDrugScreening,
  hasI3ScreenDrugScreening,
  includesAppointment,
  isV2DrugScreening,
  onlySSN,
} from '../../../lib/helpers';
import { Report as ReportInterface } from '../../../types/report';
import ReportabilityCard from './ReportabilityCard';
import { shouldShowScreening } from './lib/utils';
import InternationalCredit from './Screenings/International/InternationalCredit';

export interface Props {
  expungement: any;
  international: any;
  report: ReportInterface;
}

interface State {
  view: ReportView;
}

const companyNameOrDefault = (report: ReportInterface) =>
  report.account
    ? report.account.name
    : i18n.getStr('components.Report.company');

export class Report extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { report } = this.props;

    let startingView = ReportView.CRIMINAL_VIEW;
    if (report.drugScreening || report.occupationalHealthScreening) {
      startingView = ReportView.HEALTH_VIEW;
    } else if (report.creditReport) {
      startingView = ReportView.CREDIT_VIEW;
    } else if (report.clearinghouseSearch) {
      startingView = ReportView.CLEARINGHOUSE_VIEW;
    } else if (report.drugAlcoholClearinghouse) {
      startingView = ReportView.DRUG_ALCOHOL_CLEARINGHOUSE_VIEW;
    } else if (
      report.professionalLicenseVerifications &&
      report.professionalLicenseVerifications.length > 0
    ) {
      startingView = ReportView.PLV_VIEW;
    }

    this.state = { view: startingView };

    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick(view: ReportView) {
    this.setState({ view });
  }

  shouldDisplayCanceledNotice() {
    const { report } = this.props;

    return report.status === CANCELED_STATUS && !report.candidate.onHold;
  }

  shouldDisplayCanceledOnHold() {
    const { report } = this.props;

    return report.status === CANCELED_STATUS && report.candidate.onHold;
  }

  // TODO: deprecate suspended panel
  shouldDisplaySuspendedReportPanel() {
    const { report } = this.props;

    if (report.status !== SUSPENDED_STATUS) {
      return false;
    }

    if (report.candidate.onHold) {
      return true;
    }

    if (!report.processingDocuments && report.verifications.length === 0) {
      return true;
    }

    return false;
  }

  shouldDisplayScreenings() {
    const {
      report: {
        adverseActionCriteriaResult,
        onlyInternationalScreenings,
        status,
      },
    } = this.props;
    return (
      isStatusComplete(status) ||
      (isStatusProcessing(status) && adverseActionCriteriaResult?.result) ||
      onlyInternationalScreenings
    );
  }

  render() {
    const { expungement, international, report } = this.props;
    const { view } = this.state;
    const companyName = companyNameOrDefault(report);
    let isInternationalCreditReport;

    if (report?.creditReport?.records) {
      isInternationalCreditReport = report?.creditReport?.records?.find(
        (creditRecord: { pdfUrl: string; countryName: string }) =>
          creditRecord.countryName !== 'United States',
      );
    }

    return (
      <div data-testid='report-container'>
        {onlySSN(report) && (
          <Notice
            text={i18n.getStr('components.Report.reportIsUnableToBeCompleted')}
          />
        )}
        {this.shouldDisplayCanceledNotice() && (
          <Notice text={i18n.getStr('components.Report.reportWasCancelled')} />
        )}
        {this.shouldDisplayCanceledOnHold() && (
          <CanceledOnHoldNotice companyName={report.account.name} />
        )}
        {hasProLicenseVerificationManualEntries(report) && (
          <ProLicenseVerificationManualEntries companyName={companyName} />
        )}
        {!isStatusComplete(report.status) && report.status !== 'canceled' && (
          <Verifications
            verifications={report.verifications}
            candidate={report.candidate}
          />
        )}

        {report.status === DISPUTE_STATUS && (
          <DisputeNotice email={report.candidate.email} />
        )}
        {report.status === CONSIDER_STATUS && (
          <CompletedNotice
            email={report.candidate.email}
            expungement={expungement}
          />
        )}
        {this.shouldDisplaySuspendedReportPanel() && (
          <SuspendedNotice
            onHold={report.candidate.onHold}
            companyName={companyName}
          />
        )}
        {report.status === PENDING_STATUS &&
          report.verifications?.length === 0 && (
            <Panel>
              <PendingNotice
                copyRequested={report.candidate.copyRequested}
                applyMessaging={window.location.search.match(/apply=true/)}
              />
              <M.Eta
                createdAt={report.createdAt}
                estimatedCompletionTime={report.estimatedCompletionTime}
              />
            </Panel>
          )}
        {report.status === CLEAR_STATUS && <ClearNotice />}

        <ReportInformation report={report} international={international} />

        {view === ReportView.HEALTH_VIEW &&
          includesAppointment(report) &&
          !isStatusComplete(report.status) && (
            <CommonQuestions report={report} />
          )}

        <StatementOfDispute comments={report.disputeComments} />
        {this.shouldDisplayScreenings() && (
          <div data-testid='screenings'>
            <ViewTypeToggle
              report={report}
              view={view}
              onClick={this.handleToggleClick}
            />
            {view === ReportView.CRIMINAL_VIEW && (
              <CriminalScreenings report={report} />
            )}
            {view === ReportView.CLEARINGHOUSE_VIEW && (
              <ClearinghouseReport report={report} />
            )}
            {view === ReportView.DRUG_ALCOHOL_CLEARINGHOUSE_VIEW && (
              <DrugAlcoholClearinghouse
                search={report.drugAlcoholClearinghouse}
              />
            )}
            {view === ReportView.HEALTH_VIEW && (
              <span>
                {hasI3ScreenDrugScreening(report) && (
                  <DrugScreening search={report.drugScreening} />
                )}
                {hasEScreenDrugScreening(report) &&
                  report.drugScreening &&
                  isV2DrugScreening(report.drugScreening) &&
                  shouldShowScreening(report.drugScreening.status) && (
                    <DrugV2HealthScreening screening={report.drugScreening} />
                  )}
                {report.occupationalHealthScreening &&
                  shouldShowScreening(
                    report.occupationalHealthScreening.status,
                  ) && (
                    <OccupationalHealthScreening
                      screening={report.occupationalHealthScreening}
                    />
                  )}
              </span>
            )}
            {view === ReportView.CREDIT_VIEW &&
              !isInternationalCreditReport && (
                <CreditReport search={report.creditReport} />
              )}
            {view === ReportView.CREDIT_VIEW && isInternationalCreditReport && (
              <InternationalCredit search={report.creditReport} />
            )}
            {view === ReportView.PLV_VIEW &&
              report.professionalLicenseVerifications.map(plv => (
                <ProLicenseVerification
                  screening={plv}
                  key={plv.id}
                  candidate={report.candidate}
                  companyName={companyName}
                />
              ))}
            <ReportabilityCard companyName={companyName} />
            <CandidateActionWorkflowCards report={report} />
          </div>
        )}
        <M.Container data-testid='faq'>
          <M.GridCol className='candidate-footer-container'>
            <Faq translationKey='components.CandidateLandingPage.Faq' />
            <em>
              <I18nLink
                translationKey={[
                  `translationKey.helpCenterMessage`,
                  'components.Faq.helpCenterMessage',
                ]}
                className='faq-help-center-message'
                id='faq-help-center-message'
              />
            </em>
          </M.GridCol>
        </M.Container>
      </div>
    );
  }

  static propTypes = {
    expungement: PropTypes.object,
    international: PropTypes.bool.isRequired,
    report: PropTypes.object.isRequired,
  };

  static defaultProps = {
    expungement: undefined,
  };
}

export default i18n.renderTranslation()(Report);

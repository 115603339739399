import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import { Greeting } from './Greeting';
import { StyleableContainer, defaultStyle } from './StyleableContainer';
import { Type } from './Type';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Icon } from './Icon';
import {
  Paragraph,
  Alignment as ParagraphAlignment,
  ColorLevel as ParagraphColorLevel,
} from './Paragraph';
import { SecureDisclosure } from './SecureDisclosure';
import * as V from '../../lib/validations';
import * as N from '../../lib/normalizations';
import { SensitiveTextInput } from '../../components/fields/mastodon';
import FooterButtons from './FooterButtons';

interface SsnReentryProps {
  company?: string;
  firstName?: string;
}

export const SsnReentry: React.FC<SsnReentryProps> = ({
  company = '',
  firstName = '',
}) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
      }}
    >
      <Greeting company={company} firstName={firstName} />
      <M.Container
        style={{
          gap: '16px',
          background: colors.uiGrey50,
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          border: 'none',
          padding: '0',
        }}
      >
        <StyleableContainer
          type={Type.Info}
          styledTopBorder
          style={{ ...defaultStyle, gap: '16px' }}
        >
          <Icon type={Type.Info} />
          <Heading
            textKey='components.InstantExceptions.SsnReentry.heading'
            align={HeadingAlignment.Center}
          />
          <M.Divider style={{ width: '100%' }} />
          <Paragraph
            textKey='components.InstantExceptions.SsnReentry.detail'
            align={HeadingAlignment.Left}
          />
          <Field
            id='ssnInput'
            type='tel'
            name='ssn'
            placeholder='placeholders.ssn'
            className='ssn-reentry__input'
            labelControl={
              <>
                <span style={{ fontSize: '14px' }}>
                  {i18n.getStr(
                    'components.Report.Verification.UpdatedSsnCheck.ssnInputLabel',
                  )}
                </span>
              </>
            }
            component={SensitiveTextInput}
            normalize={N.ssn}
            validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
            mask={/\d/g}
            onPaste={(e: any) => e.preventDefault()}
            tooltip={{
              show: i18n.getStr(
                'components.Report.Verification.UpdatedSsnCheck.tooltip.show',
              ),
              hide: i18n.getStr(
                'components.Report.Verification.UpdatedSsnCheck.tooltip.hide',
              ),
            }}
          />
          <Field
            type='tel'
            name='ssnConfirmation'
            placeholder='placeholders.ssn'
            className='ssn-reentry__input'
            labelControl={
              <>
                <span style={{ fontSize: '14px' }}>
                  {i18n.getStr(
                    'components.Report.Verification.UpdatedSsnCheck.confirmSsnInputLabel',
                  )}
                </span>
              </>
            }
            component={SensitiveTextInput}
            normalize={N.ssn}
            validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
            mask={/\d/g}
            onPaste={(e: any) => e.preventDefault()}
            tooltip={{
              show: i18n.getStr(
                'components.Report.Verification.UpdatedSsnCheck.tooltip.show',
              ),
              hide: i18n.getStr(
                'components.Report.Verification.UpdatedSsnCheck.tooltip.hide',
              ),
            }}
          />
          <SecureDisclosure />
        </StyleableContainer>
        <StyleableContainer>
          <Heading
            textKey='components.InstantExceptions.SsnReentry.helpfulToKnow'
            align={HeadingAlignment.Left}
          />
          <M.UnorderedList>
            <M.ListItem>
              <Paragraph
                textKey='components.InstantExceptions.SsnReentry.weUse'
                align={ParagraphAlignment.Left}
                colorLevel={ParagraphColorLevel.Tertiary}
              />
            </M.ListItem>
            <M.ListItem>
              <Paragraph
                textKey='components.InstantExceptions.SsnReentry.yourCredit'
                align={ParagraphAlignment.Left}
                colorLevel={ParagraphColorLevel.Tertiary}
              />
            </M.ListItem>
            <M.ListItem>
              <Paragraph
                textKey='components.InstantExceptions.SsnReentry.weCant'
                align={ParagraphAlignment.Left}
                colorLevel={ParagraphColorLevel.Tertiary}
              />
            </M.ListItem>
          </M.UnorderedList>
        </StyleableContainer>
      </M.Container>
      <FooterButtons>
        <M.Button type='submit'>
          {i18n.getStr('components.InstantExceptions.SsnReentry.submit')}
        </M.Button>
      </FooterButtons>
    </M.Container>
  );
};

import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { colors } from '@dashboard-experience/mastodon';
import Typography2024Hack from './Typography2024Hack';

interface ParagraphProps {
  textKey: string;
  align?: Alignment;
  textVar?: string;
  colorLevel?: ColorLevel;
  p4?: boolean;
}

export enum Alignment {
  Center = 1,
  Left,
  Right,
}

export enum ColorLevel {
  Secondary = 1,
  Tertiary = 2,
}

export const Paragraph: React.FC<ParagraphProps> = ({
  textKey,
  align,
  textVar = '',
  colorLevel = ColorLevel.Secondary,
  p4 = false,
}) => (
  <p
    className={p4 ? 'p4' : 'p3'}
    style={{
      color:
        colorLevel === ColorLevel.Secondary
          ? colors.uiTextSecondaryLight
          : colors.uiTextTertiaryLight,
      textAlign:
        // eslint-disable-next-line no-nested-ternary
        align === Alignment.Center
          ? 'center'
          : align === Alignment.Right
          ? 'right'
          : 'left',
      width: '100%',
      ...(p4 ? Typography2024Hack.p4 : Typography2024Hack.p3),
      margin: 0,
    }}
  >
    {i18n.getStr(textKey, textVar)}
  </p>
);

import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { moment } from '@international/mastodon-i18n';
import { longDatePattern } from 'lib/dateFormat';
import { M } from '@dashboard-experience/mastodon';

const DrugScreeningAppointment = ({ report: { drugScreening } }) => {
  if (!drugScreening) {
    return null;
  }
  const { appointment, status } = drugScreening;
  return appointment ? (
    <M.Grid>
      <M.GridRow className='panel-details mb-md-4'>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='components.drugScreening.drugScreenLab' />
          </span>
          <br />
          {appointment.name}
          <br />
          {appointment.street} {appointment.unit}
          <br />
          {`${appointment.city}, ${appointment.state} ${appointment.zipcode}`}
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='components.drugScreening.labHoursOfOperation' />
          </span>
          <br />
          {appointment.locationSchedule.split('|').map(s => (
            <span key={s}>
              {s}
              <br />
            </span>
          ))}
        </M.GridCol>
        <M.GridCol md={3} className='col-md-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='components.drugScreening.scheduled' />
          </span>
          <br />
          {moment(appointment.date).format(longDatePattern())}
        </M.GridCol>
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='components.drugScreening.drugScreenStatus' />
          </span>
          <br />
          {status}
        </M.GridCol>
      </M.GridRow>
    </M.Grid>
  ) : null;
};

DrugScreeningAppointment.propTypes = {
  report: PropTypes.object.isRequired,
};

export default DrugScreeningAppointment;

export default {
  h4: {
    /* Mastodon (2024)/H4 */
    fontFamily: 'National 2',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
  h5: {
    /* Mastodon (2024)/H5 */
    fontFamily: 'National 2',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.16px',
  },
  p2Bold: {
    color: '#1A2026',

    /* Mastodon (2024)/P2 Bold */
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  p3: {
    /* Mastodon (2024)/P3 */
    fontFamily: 'Helvetica',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  p4: {
    /* Mastodon (2024)/P4 */
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  textIconBlackPrimary: {
    color: '#1A2026',
  },
  textIconBlackSecondary78: {
    color: '#1a2026bf',
  },
  button5: {
    fontFamily: '"Public Sans"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '14px',
    letterSpacing: '0.14px',
  },
};

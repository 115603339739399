import { decamelizeKeys } from 'humps';
import { request } from './helper';
import { isSessionStorageSupported } from '../lib/helpers';

import {
  CHECKR_AMS_BASE_URL,
  SESSION_STORAGE_JWT_KEY,
  SESSION_STORAGE_CANDIDATE_IDS_KEY,
} from '../constants';

/*
 * Action Types
 */

export const CHECK_FOR_ACTIVE_AUTH = 'CHECK_FOR_ACTIVE_AUTH';
export const checkForActiveAuth = () => ({
  type: CHECK_FOR_ACTIVE_AUTH,
});

export const CHECK_FOR_ACTIVE_AUTH_COMPLETE = 'CHECK_FOR_ACTIVE_AUTH_COMPLETE';
export const checkForActiveAuthComplete = payload => ({
  type: CHECK_FOR_ACTIVE_AUTH_COMPLETE,
  payload,
});

export const LOGOUT_USER_COMPLETE = 'LOGOUT_USER_COMPLETE';
export const logoutUserComplete = () => ({
  type: LOGOUT_USER_COMPLETE,
});

export const SUBMIT_CANDIDATE_EMAIL_REQUEST = 'SUBMIT_CANDIDATE_EMAIL_REQUEST';
export const submitCandidateEmailRequestAction = () => ({
  type: SUBMIT_CANDIDATE_EMAIL_REQUEST,
});

export const SUBMIT_CANDIDATE_EMAIL_SUCCESS = 'SUBMIT_CANDIDATE_EMAIL_SUCCESS';
export const submitCandidateEmailSucessAction = payload => ({
  type: SUBMIT_CANDIDATE_EMAIL_SUCCESS,
  payload,
});

export const SUBMIT_CANDIDATE_EMAIL_FAILURE = 'SUBMIT_CANDIDATE_EMAIL_FAILURE';
export const submitCandidateEmailFailureAction = () => ({
  type: SUBMIT_CANDIDATE_EMAIL_FAILURE,
});

export const SUBMIT_CANDIDATE_LOGIN_REQUEST = 'SUBMIT_CANDIDATE_LOGIN_REQUEST';
export const submitCandidateLoginRequestAction = payload => ({
  type: SUBMIT_CANDIDATE_LOGIN_REQUEST,
  payload,
});

export const SUBMIT_CANDIDATE_LOGIN_SUCCESS = 'SUBMIT_CANDIDATE_LOGIN_SUCCESS';
export const submitCandidateLoginSuccessAction = payload => ({
  type: SUBMIT_CANDIDATE_LOGIN_SUCCESS,
  payload,
});

export const SUBMIT_CANDIDATE_LOGIN_FAILURE = 'SUBMIT_CANDIDATE_LOGIN_FAILURE';
export const submitCandidateLoginFailureAction = payload => ({
  type: SUBMIT_CANDIDATE_LOGIN_FAILURE,
  payload,
});

/*
 * Actions
 */
export const checkForActiveAuthSession = () => dispatch => {
  dispatch(checkForActiveAuth());

  if (!isSessionStorageSupported()) {
    return dispatch(
      checkForActiveAuthComplete({
        loggedIn: false,
      }),
    );
  }

  const existingSession = window.sessionStorage.getItem(
    SESSION_STORAGE_JWT_KEY,
  );
  const existingCandidateIds = window.sessionStorage.getItem(
    SESSION_STORAGE_CANDIDATE_IDS_KEY,
  );

  const candidateJwt = existingSession || '';
  const candidateIds = existingCandidateIds
    ? existingCandidateIds.split(',')
    : [];

  return dispatch(
    checkForActiveAuthComplete({
      loggedIn: !!existingSession,
      candidateJwt,
      candidateIds,
    }),
  );
};

export const logoutUser = () => dispatch => {
  dispatch(logoutUserComplete());
};

export const submitCandidateEmail = values => dispatch => {
  const { dob, email, recaptchaCode } = values;

  const year = parseInt(dob.year.selectedItem, 10);
  const month = () => {
    const formValue = dob.month.selectedItem;
    const monthArray = formValue.split(' ');
    return parseInt(monthArray[0], 10);
  };
  const day = parseInt(dob.day.selectedItem, 10);

  const body = JSON.stringify(
    decamelizeKeys({
      email,
      dob: { year, month: month(), day },
      recaptchaCode,
    }),
  );
  const options = {
    method: 'POST',
    body,
    retries: 0,
  };

  dispatch(submitCandidateEmailRequestAction());

  return request(
    `${CHECKR_AMS_BASE_URL}/api/public/v1/candidates/auth/one_time_access_link`,
    options,
  )
    .then(_ => dispatch(submitCandidateEmailSucessAction()))
    .catch(_ => dispatch(submitCandidateEmailFailureAction()));
};

export const submitCandidateLinkLogin =
  ({ verificationCode, email }) =>
  dispatch => {
    verificationCode = decodeURI(verificationCode);
    email = decodeURI(email);

    dispatch(submitCandidateLoginRequestAction({ candidateEmail: email }));

    const options = {
      method: 'POST',
      body: JSON.stringify(decamelizeKeys({ verificationCode, email })),
      retries: 0,
    };

    return request(
      `${CHECKR_AMS_BASE_URL}/api/public/v1/candidates/auth/token`,
      options,
    )
      .then(json => {
        const { candidateIds, token } = json;

        if (token && candidateIds) {
          if (isSessionStorageSupported()) {
            window.sessionStorage.setItem(SESSION_STORAGE_JWT_KEY, token);
            window.sessionStorage.setItem(
              SESSION_STORAGE_CANDIDATE_IDS_KEY,
              candidateIds,
            );
          }

          dispatch(
            submitCandidateLoginSuccessAction({
              candidateJwt: token,
              candidateIds,
            }),
          );
        }
      })
      .catch(error => {
        const errorKeys = ['notFound', 'expired', 'exceededAttempts'];
        const errorMessage = errorKeys.includes(error.message)
          ? `errors.${error.message}`
          : 'errors.notFound';

        dispatch(submitCandidateLoginFailureAction(errorMessage));
      });
  };

import React, { useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFlagr } from 'api/flagr';
import { Navigate, useParams } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import {
  CHECKR_BFF_API_BASE,
  FLAGR_KEYS,
  INSTANT_EXCEPTIONS_FORM_NAME,
} from '../../constants';
import MainContainer from '../../components/MainContainer';
import { StartedYourBackgroundCheck as StartedYourBackgroundCheckPage } from './StartedYourBackgroundCheck';
import { ReceivedYourInfo as ReceivedYourInfoPage } from './ReceivedYourInfo';
import { UploadDocumentLater as UploadDocumentLaterPage } from './UploadDocumentLater';
import { SsnReentry as SsnReentryPage } from './SsnReentry';
import { SsnUpload as SsnUploadPage } from './SsnUpload';
import { UploadDeadend as UploadDeadendPage } from './UploadDeadend';
import { DocumentUpload as DocumentUploadPage } from './DocumentUpload';
import { Waiting as WaitingPage } from './Waiting';

export enum Page {
  Waiting = 'Waiting',
  StartedYourBackgroundCheck = 'Started Your Background Check',
  ReceivedYourInfo = 'Received your info',
  UploadDocumentLater = 'Upload document later',
  SsnReentry = 'SSN Reentry',
  SsnUpload = 'SSN Upload',
  UploadDeadend = "Upload deadend (can't accept uploads)",
  DocumentUpload = 'Document upload',
}

interface ExceptionJson {
  status: string;
  exception_id?: string;
  info_required_type?: string;
}

interface InstantExceptionsFormProps {
  handleSubmit: any;
}

const InstantExceptionsForm: React.FC<InstantExceptionsFormProps> = ({
  handleSubmit,
}) => {
  const params = useParams();
  const reportId = params?.reportId?.replace(/[^a-fA-F0-9]/g, '').slice(0, 24);

  const [page, setPage] = useState(Page.Waiting);
  const [isPolling, setIsPolling] = useState(true);

  const { flag, isFetched: flagIsLoaded } = useFlagr({
    context: {
      locationHost: window.location.host,
    },
    id: FLAGR_KEYS.instantExceptionsEnabled,
    entity: { id: reportId, type: 'report' },
    fallbackVariant: 'on',
  });
  const { variantKey: instantExceptionsEnabled } = flag;

  const POLLING_INTERVAL = 5000;
  useEffect(() => {
    if (isPolling) {
      const interval = setInterval(() => {
        fetchException(reportId).then(json => {
          if (json.status === 'done') {
            setIsPolling(false);
            setPage(Page.StartedYourBackgroundCheck);
          } else if (json.status === 'info_required') {
            setIsPolling(false);
            handleInfoRequired(json);
          }
        });
      }, POLLING_INTERVAL);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [isPolling, reportId]);

  async function fetchException(
    id: string | undefined,
  ): Promise<ExceptionJson> {
    try {
      const response = await fetch(
        `${CHECKR_BFF_API_BASE}/ssn_trace_status?report_id=${id}`,
      );
      const json = await response.json();
      return json;
    } catch (e) {
      return { status: 'errror' };
    }
  }

  const handleInfoRequired = (exceptionJson: ExceptionJson) => {
    if (exceptionJson.info_required_type === 'thin_file') {
      setPage(Page.SsnUpload);
    } else if (exceptionJson.info_required_type === 'name_or_dob_mismatch') {
      setPage(Page.SsnReentry);
    }
  };

  if (flagIsLoaded && instantExceptionsEnabled !== 'on') {
    return <Navigate to='/' />;
  }

  let pagePart = null;
  switch (page) {
    case Page.Waiting:
      pagePart = <WaitingPage company='ACME' firstName='Amy' />;
      break;
    case Page.StartedYourBackgroundCheck:
      pagePart = (
        <StartedYourBackgroundCheckPage company='ACME' firstName='Amy' />
      );
      break;
    case Page.ReceivedYourInfo:
      pagePart = <ReceivedYourInfoPage company='ACME' firstName='Amy' />;
      break;
    case Page.UploadDocumentLater:
      pagePart = <UploadDocumentLaterPage company='ACME' firstName='Amy' />;
      break;
    case Page.SsnReentry:
      pagePart = <SsnReentryPage company='ACME' firstName='Amy' />;
      break;
    case Page.SsnUpload:
      pagePart = <SsnUploadPage company='ACME' firstName='Amy' />;
      break;
    case Page.UploadDeadend:
      pagePart = <UploadDeadendPage company='ACME' firstName='Amy' />;
      break;
    case Page.DocumentUpload:
      pagePart = <DocumentUploadPage company='ACME' firstName='Amy' />;
      break;
    default:
      pagePart = <h1>NYI</h1>;
      break;
  }

  // const handleSubmit = (e: any) => {
  //   console.log('submitted form', e);
  // };

  return (
    <MainContainer style={{ background: colors.uiGrey400 }}>
      {flagIsLoaded ? (
        <M.Container style={{ background: colors.uiGrey50 }}>
          <form onSubmit={handleSubmit}>
            <M.Select
              hideLabel
              id='page'
              defaultValue={page}
              onChange={(e: {
                target: { value: React.SetStateAction<Page> };
              }) => setPage(e.target.value)}
            >
              {Object.values(Page).map(key => (
                <M.Select.Item key={key} value={key} text={key} />
              ))}
            </M.Select>
            <p>selected page {page}</p>
            {pagePart}
          </form>
        </M.Container>
      ) : null}
    </MainContainer>
  );
};

const ContactForm = reduxForm({
  form: INSTANT_EXCEPTIONS_FORM_NAME,
})(InstantExceptionsForm);

class InstantExceptions extends React.Component {
  submit = (values: any) => {
    // print the form values to the console
    // TODO: handle the form inputs
    // eslint-disable-next-line no-console
    console.log(values);
  };

  render() {
    return <ContactForm onSubmit={this.submit} />;
  }
}

export default InstantExceptions;

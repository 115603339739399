import React from 'react';
import PropTypes from 'prop-types';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import {
  getUnmaskedDobOrDefaultMasked,
  checkContextForUnmask,
} from './InternationalProfile';
import { dateWithTimezone } from '../lib/formatting';

const CanadaProfile = ({ report, pdf }) => (
  <M.Grid>
    <M.GridRow className='mb-md-4'>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.firstName' />
        </span>
        <br />
        {report.candidate.firstName}
      </M.GridCol>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.middleName' />
        </span>
        <br />
        {report.candidate.middleName || '-'}
      </M.GridCol>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.lastName' />
        </span>
        <br />
        {report.candidate.lastName}
      </M.GridCol>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.dob' />
        </span>
        <br />
        {getUnmaskedDobOrDefaultMasked(
          report?.candidate?.dob,
          checkContextForUnmask(report),
        )}
      </M.GridCol>
    </M.GridRow>

    <M.GridRow className='mb-md-4'>
      {report.candidate.birthPlace && (
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.birthPlace' />
          </span>
          <br />
          {report.candidate.birthPlace}
        </M.GridCol>
      )}
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.phone' />
        </span>
        <br />
        {report.candidate.phone}
      </M.GridCol>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.email' />
        </span>
        <br />
        <div className='text-truncate' title={report.candidate.email}>
          {report.candidate.email}
        </div>
      </M.GridCol>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.driverLicenseNumber' />
        </span>
        <br />
        {report.candidate.driverLicenseNumber &&
        report.candidate.driverLicenseProvince ? (
          <span>
            {report.candidate.driverLicenseNumber} (
            {report.candidate.driverLicenseProvince})
          </span>
        ) : (
          <span>-</span>
        )}
      </M.GridCol>
    </M.GridRow>

    <M.GridRow className='mb-md-4'>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.createdAt' />
        </span>
        <br />
        {dateWithTimezone(report, 'createdAt', pdf)}
      </M.GridCol>
      <M.GridCol md={3} className='mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.completedAt' />
        </span>
        <br />
        {report.completedAt
          ? dateWithTimezone(report, 'completedAt', pdf)
          : '-'}
      </M.GridCol>
      {report.revisedAt && (
        <M.GridCol md={3} className='mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.revisedAt' />
          </span>
          <br />
          {dateWithTimezone(report, 'revisedAt', pdf)}
        </M.GridCol>
      )}
    </M.GridRow>
  </M.Grid>
);

CanadaProfile.propTypes = {
  report: PropTypes.object.isRequired,
  pdf: PropTypes.bool,
};

CanadaProfile.defaultProps = {
  pdf: false,
};

export default CanadaProfile;

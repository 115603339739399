import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import Typography2024Hack from './Typography2024Hack';

export const SecureDisclosure: React.FC = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: '4px',
      alignSelf: 'stretch',
    }}
  >
    <M.Icon
      icon='Locked'
      style={{
        width: '16px',
        height: '16px',
        margin: '4px',
        marginTop: '0',
        fill: colors.uiTextTertiaryLight,
      }}
    />
    <p
      className='p4'
      style={{ color: colors.uiTextTertiaryLight, ...Typography2024Hack.p4 }}
    >
      {i18n.getStr('components.InstantExceptions.secureDisclosure')}
    </p>
  </div>
);

import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { text } from '../../lib/formatting';
import { formatSearchTitle } from '../../lib/screenings';
import Status from '../../Status';
import Attribute from '../../Attribute';

const IntlMotorVehicleReport = ({ record }) => {
  const getScreeningTitle = licenseInfo => {
    const headingTranslation = licenseInfo.isCurrent
      ? 'components.Report.MotorVehicleReport.currentDriverInfo'
      : 'components.Report.MotorVehicleReport.driverInfo';
    return (
      <strong>
        {i18n.getStr(headingTranslation)} ({licenseInfo.subdivision?.slice(-2)}{' '}
        {i18n.getStr('components.Report.MotorVehicleReport.class')}{' '}
        {licenseInfo.licenseClass})
      </strong>
    );
  };

  const renderAttrWithIcon = (attr, value, isResultValid) => {
    const attrText = text(attr);
    const iconStyle = isResultValid ? (
      <M.Icon icon='CheckmarkFilled' className='status-clear' />
    ) : (
      <M.Icon icon='CheckmarkFilled' className='status-consider' />
    );
    return (
      <M.Grid>
        <M.GridRow className='mb-1'>
          <M.GridCol sm={4} md={3} className='text-very-muted'>
            {attrText}
          </M.GridCol>
          <M.GridCol sm={8} md={9}>
            {iconStyle} {value || '-'}
          </M.GridCol>
        </M.GridRow>
      </M.Grid>
    );
  };

  const renderViolations = violations => {
    return (
      <>
        <strong>
          {i18n.getStr('components.Report.MotorVehicleReport.violations')}
        </strong>
        {violations.map((violation, i) => {
          return (
            <table
              style={{ tableLayout: 'fixed', width: '100%' }}
              key={`violation-${i + 1}`}
            >
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left', paddingTop: '10px' }}>
                    <M.Icon
                      icon='CheckmarkFilled'
                      className='status-clear'
                      style={{ paddingRight: '5px' }}
                    />
                    {violation.description.toUpperCase()}
                  </td>
                  <td style={{ textAlign: 'right', paddingTop: '10px' }}>
                    {i18n.getLocalizedShortDateFormat(violation.issuedDate) ||
                      '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      </>
    );
  };

  const renderHyperlink = (attr, url, linkText) => {
    const attrText = text(attr);
    return (
      <M.Grid>
        <M.GridRow className='mb-1'>
          <M.GridCol sm={4} md={3} className='text-very-muted'>
            {attrText}
          </M.GridCol>
          <M.GridCol sm={8} md={9}>
            <a href={url} target='_blank' rel='noopener noreferrer'>
              {linkText}
            </a>
          </M.GridCol>
        </M.GridRow>
      </M.Grid>
    );
  };

  const renderLicenseReports = (licenseReports, pdfUrl) => {
    return (
      <>
        {licenseReports.map((licenseReport, i) => {
          return (
            <M.Expandable
              key={`licenseReport-${i + 1}`}
              title={getScreeningTitle(licenseReport.licenseInfos[0])}
              initialExpanded
            >
              <Attribute
                attr={i18n.getStr('labels.countryOfIssue')}
                value={
                  i18n.getCountry(licenseReport.licenseInfos[0].country) || '-'
                }
              />
              <Attribute
                attr={i18n.getStr('labels.province')}
                value={
                  licenseReport.licenseInfos[0].subdivision?.slice(-2) || '-'
                }
              />
              {renderAttrWithIcon(
                i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseStatus',
                ),
                licenseReport.licenseStatus,
                licenseReport.licenseStatus.toUpperCase().startsWith('VALID'),
              )}
              {renderAttrWithIcon(
                i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseClass',
                ),
                licenseReport.licenseInfos[0].licenseClass,
                licenseReport.isValidLicenseClass,
              )}
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseNumber',
                )}
                value={licenseReport.licenseInfos[0].licenseNumber || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseExpirationDate',
                )}
                value={
                  i18n.getLocalizedShortDateFormat(
                    licenseReport.licenseInfos[0].expirationDate,
                  ) || '-'
                }
              />
              <strong>{i18n.getStr('labels.results')}</strong>
              {pdfUrl
                ? renderHyperlink(
                    i18n.getStr(
                      'components.Report.MotorVehicleReport.downloadResult',
                    ),
                    pdfUrl,
                    i18n.getStr(
                      'components.Report.MotorVehicleReport.pdfUrlName',
                    ),
                  )
                : null}
              {renderAttrWithIcon(
                i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseStatus',
                ),
                licenseReport.licenseStatus,
                licenseReport.licenseStatus.toUpperCase().startsWith('VALID'),
              )}
              {renderAttrWithIcon(
                i18n.getStr(
                  'components.Report.MotorVehicleReport.actualLicenseClass',
                ),
                licenseReport.actualLicenseClass,
                licenseReport.isValidLicenseClass,
              )}
              {licenseReport.violations.length > 0
                ? renderViolations(licenseReport.violations)
                : null}
            </M.Expandable>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div id='international-motor-vehicle-report'>
        <div className='d-flex mb-3'>
          <div>
            <strong> {formatSearchTitle(record)} </strong>
            {record.turnaroundTime && (
              <span>
                {i18n.getLocalizedShortDateTimeFormat(record.completedAt)} (
                {i18n.getLocalizedDuration(record.turnaroundTime)})
              </span>
            )}
          </div>
          <div className='ml-auto'>
            <Status status={record.status} />
          </div>
        </div>
      </div>
      {renderLicenseReports(record.licenseReports, record.pdfUrl)}
    </>
  );
};

IntlMotorVehicleReport.propTypes = {
  record: PropTypes.object,
};

IntlMotorVehicleReport.defaultProps = {
  record: null,
};

export default IntlMotorVehicleReport;

import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import { InternationalCreditRecordObject } from '../../../../../types/report';
import {
  InternationalCreditReportRecordBox,
  InternationalCreditReportRecordLine,
  InternationalCreditBoldCaption,
} from './InternationalCreditStyledComponents';

interface InternationalCreditReportEmptyRecordProps {
  record: InternationalCreditRecordObject;
}

const InternationalCreditEmptyReportRecord = ({
  record,
}: InternationalCreditReportEmptyRecordProps) =>
  !!record && (
    <div>
      <InternationalCreditReportRecordBox>
        <InternationalCreditReportRecordLine>
          <InternationalCreditBoldCaption>
            {record.countryName}{' '}
            {i18n.getStr('components.internationalCreditReportCaption')}
          </InternationalCreditBoldCaption>
        </InternationalCreditReportRecordLine>
        <div>
          <M.StatusBadge statusType={StatusTypes.Legacy} status='Pending' />
        </div>
      </InternationalCreditReportRecordBox>
    </div>
  );

InternationalCreditEmptyReportRecord.propTypes = {
  record: PropTypes.object,
};

InternationalCreditEmptyReportRecord.defaultProps = {
  record: {},
};

export default i18n.renderTranslation()(InternationalCreditEmptyReportRecord);

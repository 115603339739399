import { colors } from '@dashboard-experience/mastodon';

export enum Type {
  Default = 1,
  Info,
  Success,
  Fail,
  Upload,
}

export const TypeParams = {
  [Type.Default]: { color: colors.uiGrey300, icon: null },
  [Type.Info]: { color: colors.uiOrange400, icon: 'WarningFilled' },
  [Type.Success]: { color: colors.uiAqua500, icon: 'CheckmarkFilled' },
  [Type.Fail]: { color: colors.uiOrange500, icon: 'ErrorFilled' },
  [Type.Upload]: { color: colors.uiTextPrimaryLight, icon: 'Upload' },
};

import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Status from '../Status';
import { date, text, numberIfNumber } from '../lib/formatting';
import { isEmpty } from '../../../../lib/helpers';

// Mapping from monolith enum contract types to i18n translation keys.
const CONTRACT_TYPE_I18N_KEYS = {
  full_time: 'components.employment.contractTypes.fullTime',
  part_time: 'components.employment.contractTypes.partTime',
  contract: 'components.employment.contractTypes.contract',
  internship: 'components.employment.contractTypes.internship',
  self_employed: 'components.employment.contractTypes.selfEmployed',
  seasonal: 'components.employment.contractTypes.seasonal',
  temporary: 'components.employment.contractTypes.temporary',
  other: 'components.employment.contractTypes.other',
};

const FIELD_NAME_I18N_KEYS = {
  position: 'components.employment.fieldNames.position',
  startDate: 'components.employment.fieldNames.startDate',
  endDate: 'components.employment.fieldNames.endDate',
  contractType: 'components.employment.fieldNames.contractType',
  salary: 'components.employment.fieldNames.salary',
};

const getPresentText = () => {
  return i18n.getStr('components.employment.present');
};
const getNotAvailableText = () => {
  return i18n.getStr('components.employment.notAvailable');
};

const EmploymentRow = ({ record, field }) => {
  // backend returned data
  const candidateInput = record.employer[field];
  const vendorResult = record.result[field];
  const verified = vendorResult?.verified;
  const ignored = vendorResult?.ignored;

  // data to be displayed by UI
  let candidate;
  let employer;

  const isDateField = field.endsWith('Date');
  if (isDateField) {
    candidate = date(
      candidateInput,
      i18n.getStr('dateFormats.monthYearWithComma'),
    );
    employer = vendorResult.comments;

    // endDate gets special treatment for currently employed histories
    if (field === 'endDate') {
      candidate = candidate || getPresentText();
      const employerFallback = verified
        ? getPresentText()
        : getNotAvailableText();
      employer = isEmpty(employer) ? employerFallback : employer;
    }
  } else if (field === 'position') {
    candidate = candidateInput;
    employer = vendorResult.comments;
  } else if (field === 'contractType') {
    candidate =
      i18n.getStr(CONTRACT_TYPE_I18N_KEYS[candidateInput]) ||
      text(candidateInput);
    employer = vendorResult.comments;
  } else if (field === 'salary') {
    candidate = candidateInput ? `$${numberIfNumber(candidateInput)}` : null;
    employer = vendorResult.comments
      ? `$${numberIfNumber(vendorResult.comments)}`
      : null;
  } else {
    candidate = text(candidateInput);
    employer = vendorResult.comments;
  }

  const [icon, setIcon] = React.useState('');

  React.useEffect(() => {
    if (!ignored && !verified) {
      setIcon('WarningAltFilled');
    } else if (ignored) {
      setIcon('WarningFilled');
    }
  }, [ignored, verified]);

  const fieldNameText = i18n.getStr(FIELD_NAME_I18N_KEYS[field]) || text(field);

  return (
    <tr>
      <th scope='row' className='text-very-muted'>
        {fieldNameText}
      </th>
      <td>{candidate || getNotAvailableText()}</td>
      <td>
        {employer || getNotAvailableText()}
        {icon && <M.Icon icon={icon} className='float-right' />}
      </td>
    </tr>
  );
};

EmploymentRow.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};

const Company = ({ employer }) => {
  const {
    name,
    address: { city, state, country },
  } = employer;

  const formattedCompany = [name, city, state, country]
    .filter(Boolean)
    .join(', ');
  return <b>{formattedCompany}</b>;
};

Company.propTypes = {
  employer: PropTypes.object,
};

Company.defaultProps = {
  employer: {},
};

const Position = ({ employer }) => (
  <div>
    <b>
      <T value='components.employment.position' />
    </b>
    <br />
    {employer.position}
    <br />
    {date(
      employer.startDate,
      i18n.getStr('dateFormats.monthYearWithComma'),
    )} -{' '}
    {employer.endDate
      ? date(employer.endDate, i18n.getStr('dateFormats.monthYearWithComma'))
      : getPresentText()}
  </div>
);

Position.propTypes = {
  employer: PropTypes.object.isRequired,
};

const Manager = ({ employer }) => {
  const {
    doNotContact,
    manager: { name, position, email, phone },
  } = employer;

  const infoProvided = name || position || email || phone;

  return (
    <div>
      <b>
        <T value='components.employment.manager' />
      </b>
      <br />
      {doNotContact && (
        <span className='text-very-muted'>
          <T value='components.employment.candidateRequestedToNotContact' />
        </span>
      )}
      {!infoProvided && !doNotContact && (
        <span className='text-very-muted'>
          <T value='components.employment.noContactInfo' />
        </span>
      )}
      {infoProvided && !doNotContact && (
        <span>
          {name}, {position}
          <br />
          {email} &middot; {phone}
        </span>
      )}
    </div>
  );
};

Manager.propTypes = {
  employer: PropTypes.object,
};

Manager.defaultProps = {
  employer: {},
};

const EmploymentRecord = ({ record }) => {
  const employmentRecordFields = [
    'position',
    'startDate',
    'endDate',
    'contractType',
  ];
  if (
    record.result.salary.ignored === undefined ||
    record.result.salary.ignored === null
  ) {
    employmentRecordFields.push('salary');
  }
  return (
    record.status !== 'canceled' && (
      <div className='employment-record'>
        <div className='d-flex'>
          <div>
            <Company employer={record.employer} />
          </div>
          <div className='ml-auto'>
            <Status status={record.status} />
          </div>
        </div>
        <hr />
        <M.Grid>
          <M.GridRow className='mb-4'>
            <M.GridCol sm={6} className='mb-2 mb-sm-0'>
              <Position employer={record.employer} />
            </M.GridCol>
            <M.GridCol sm={2} className='mb-2 mb-sm-0'>
              <Manager employer={record.employer} />
            </M.GridCol>
          </M.GridRow>
        </M.Grid>
        <div className='table-responsive'>
          <table className='table table-sm table-bottom-bordered'>
            <thead>
              <tr>
                <td />
                <td className='text-muted'>
                  <T value='components.employment.candidateInput' />
                </td>
                <td className='text-muted'>
                  <T value='components.employment.employerResponse' />
                </td>
              </tr>
            </thead>
            <tbody>
              {employmentRecordFields.map((field, i) => (
                <EmploymentRow
                  key={`row-${i + 1}`}
                  record={record}
                  field={field}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

EmploymentRecord.propTypes = {
  record: PropTypes.object,
};

EmploymentRecord.defaultProps = {
  record: {},
};

export default i18n.renderTranslation()(EmploymentRecord);

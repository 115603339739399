import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Greeting } from './Greeting';
import { StyleableContainer, defaultStyle } from './StyleableContainer';
import { Type } from './Type';
import Typography2024Hack from './Typography2024Hack';

interface WaitingProps {
  company?: string;
  firstName?: string;
}

export const Waiting: React.FC<WaitingProps> = ({
  company = '',
  firstName = '',
}) => {
  const randomWidths = [1, 2, 3].map(i => {
    return { width: `${40 + Math.floor(Math.random() * 50)}%`, i };
  });
  return (
    <M.Container style={{ background: colors.uiGrey50 }}>
      <Greeting company={company} firstName={firstName} />
      <StyleableContainer
        type={Type.Default}
        styledTopBorder
        style={{
          ...defaultStyle,
          gap: '24px',
          padding: '24px 16px 24px 16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
        }}
      >
        <p
          style={{
            ...Typography2024Hack.p2Bold,
          }}
          className='p2'
        >
          {i18n.getStr('components.InstantExceptions.Waiting.holdTight')}
        </p>
        <M.LoadingBlock style={{ height: '31px', width: '75%' }} />
        {randomWidths.map(width => (
          <M.LoadingBlock
            style={{ height: '31px', width: width.width }}
            key={`bar-${width.i}`}
          />
        ))}
        <M.LoadingBlock style={{ height: '31px' }} />
      </StyleableContainer>
    </M.Container>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Panel from '../../../Panels';
import CreditReportRecord from '../Record/Credit';

const CreditReport = ({ search }) => {
  const domesticCreditRecords = search?.records?.filter(
    creditRecord =>
      !creditRecord.pdfUrl && creditRecord.countryName === 'United States',
  );

  return (
    !!search &&
    domesticCreditRecords.length > 0 && (
      <Panel
        data-testid='credit-report'
        title={i18n.getStr('components.creditReport')}
        status={search.status}
      >
        {domesticCreditRecords.map((record, i) => (
          <CreditReportRecord
            key={`creditReportRecord-${i + 1}`}
            record={record}
            completedDate={search.completedAt}
          />
        ))}
      </Panel>
    )
  );
};

CreditReport.propTypes = {
  search: PropTypes.object,
};

CreditReport.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(CreditReport);

import React from 'react';
import PropTypes from 'prop-types';
import { Translate as T } from 'react-redux-i18n';
import { momenttz } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { dateWithTimezone } from '../lib/formatting';

const licenseText = (number, state) => `${number} (${state})`;

const getDriverLicenses = (report, isCurrent) => {
  const { motorVehicleReport, candidate } = report;

  if (motorVehicleReport && motorVehicleReport.licenseReports) {
    const licenses = motorVehicleReport.licenseReports
      .filter(licenseReport => licenseReport.currentLicense === isCurrent)
      .map(licenseReport =>
        licenseText(licenseReport.number, licenseReport.state),
      );

    if (licenses.length > 0) {
      return licenses.join(', ');
    }
  } else if (
    isCurrent &&
    candidate.driverLicenseNumber &&
    candidate.driverLicenseState
  ) {
    return licenseText(
      candidate.driverLicenseNumber,
      candidate.driverLicenseState,
    );
  } else if (
    !isCurrent &&
    candidate.previousDriverLicenseNumber &&
    candidate.previousDriverLicenseState
  ) {
    return licenseText(
      candidate.previousDriverLicenseNumber,
      candidate.previousDriverLicenseState,
    );
  }
  return '-';
};

// TODO: Use this in the CanadaProfile and the International
const GridItem = ({ label, children }) => (
  <M.GridCol md={3} className='mb-3 mb-md-0'>
    <span className='text-very-muted'>
      <T value={label} />
    </span>
    <br />
    {children}
  </M.GridCol>
);

const USProfile = ({ report, pdf, segmentationEnabled }) => (
  <M.Grid>
    <M.GridRow className='mb-md-4'>
      <GridItem label='labels.firstName'>{report.candidate.firstName}</GridItem>
      <GridItem label='labels.middleName'>
        {report.candidate.middleName || '-'}
      </GridItem>
      <GridItem label='labels.lastName'>{report.candidate.lastName}</GridItem>
      <GridItem label='labels.dob'>
        {momenttz(report.candidate.dob).format('MMM DD, [XXXX]')}
      </GridItem>
    </M.GridRow>

    <M.GridRow className='mb-md-4'>
      <GridItem label='labels.phone'>{report.candidate.phone}</GridItem>
      <GridItem label='labels.zipcode'>{report.candidate.zipcode}</GridItem>
      <GridItem label='labels.email'>
        <div className='text-wrap' title={report.candidate.email}>
          {report.candidate.email}
        </div>
      </GridItem>
      <GridItem label='labels.ssn'>{report.candidate.ssn || '-'}</GridItem>
    </M.GridRow>

    <M.GridRow className='mb-md-4'>
      <GridItem label='labels.driverLicenseNumber'>
        <span>{getDriverLicenses(report, true)}</span>
      </GridItem>
      <GridItem label='labels.previousDriverLicenses'>
        <span>{getDriverLicenses(report, false)}</span>
      </GridItem>

      {report.candidate.motherMaidenName && (
        <GridItem label='labels.motherMaidenName'>
          {report.candidate.motherMaidenName}
        </GridItem>
      )}
      {report.geos && report.geos.length > 0 && (
        <GridItem
          label={segmentationEnabled ? 'labels.complianceGeos' : 'labels.geos'}
        >
          {report.geos
            .map(geo => {
              const domesticGeo = !geo.country || geo.country === 'US';
              if (segmentationEnabled && domesticGeo) {
                return [geo.state, geo.city].filter(x => x).join(' - ');
              }
              return geo.name;
            })
            .join(', ')}
        </GridItem>
      )}
      {report.workLocations && report.workLocations.length > 0 && (
        <GridItem label='labels.workLocations'>
          {' '}
          {report.workLocations
            .map(({ country, state, city }) => {
              return [country, state, city].filter(x => x).join(' - ');
            })
            .join(', ')}
        </GridItem>
      )}
      {report.candidate.customId && (
        <GridItem label='labels.customId'>{report.candidate.customId}</GridItem>
      )}
    </M.GridRow>

    <M.GridRow className='mb-md-4'>
      <GridItem label='labels.createdAt'>
        {dateWithTimezone(report, 'createdAt', pdf)}
      </GridItem>
      <GridItem label='labels.completedAt'>
        {' '}
        {report.completedAt
          ? dateWithTimezone(report, 'completedAt', pdf)
          : '-'}
      </GridItem>
      {report.revisedAt && (
        <GridItem label='labels.revisedAt'>
          {dateWithTimezone(report, 'revisedAt', pdf)}
        </GridItem>
      )}
    </M.GridRow>
  </M.Grid>
);

USProfile.propTypes = {
  pdf: PropTypes.bool,
  report: PropTypes.object.isRequired,
  segmentationEnabled: PropTypes.bool,
};

GridItem.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

USProfile.defaultProps = {
  pdf: false,
  segmentationEnabled: false,
};

export default USProfile;

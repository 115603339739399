import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginErrorNotification from 'components/LoginErrorNotification/LoginErrorNotification';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReduxStore } from 'providers/types';
import Panel from '../Panels';
import {
  CHECKR_APPLICANTS_EU_BASE_URL,
  PATHS,
  RECAPTCHA_SITE_KEY,
} from '../../constants';
import { clearFetchReportsFailure, trackAnalyticsEvent } from '../../actions';
import { Select, Text, SensitiveInput } from '../fields';
import * as N from '../../lib/normalizations';
import * as O from '../../lib/options';
import * as V from '../../lib/validations';
import { ANALYTICS_EVENTS } from '../../lib/analytics';

const TStyled = styled(T)`
  font-size: 1.25rem !important;
  font-weight: 500 !important;
`;

const ButtonWrapperStyled = styled.div`
  &::after {
    display: block;
    clear: both;
    content: '';
  }
`;

const ButtonStyled = styled(M.Button)`
  float: right;
`;

const PanelStyled = styled(Panel)`
  background-color: ${colors.white};
  border: 1px solid ${colors.coolBlack10};
`;

const InputStyled = styled.div`
  width: 100%;
  margin-left: 20px;

  label {
    color: ${colors.slate07} !important};
    font-weight: 300;
  }

  @media (min-width: 768px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
`;

const DobWrapperStyled = styled.div`
  margin-bottom: 1rem;

  @media (min-width: 991px) {
    .card .row:last-child & {
      margin-bottom: 0;
    }
    &.multiple-form-group .row:last-child {
      margin-bottom: 23px;
    }
  }
`;

const DobLabelStyled = styled(M.FormLabel)`
  margin-left: 20px;
  color: ${colors.slate07} !important;
  font-weight: 300 !important;

  &.invalid-feedback {
    color: ${colors.dangerRed100} !important;
  }
`;

const EmailAuthLink = styled(Link)`
  padding: 0 15px;
  margin: 9px 0;
  font-size: 14px;
  line-height: 1.19em;
`;

const LOGIN_FORM_NAME = 'login';

interface LoginFormValues {
  phone: string;
  email: string;
  ssn: string;
  dob: {
    month: string;
    day: string;
    year: string;
  };
  recaptchaCode: string;
}

interface LoginFormProps extends InjectedFormProps<LoginFormValues> {
  fetchError?: string;
  processing: boolean;
  loginSource: string;
  handleSubmit: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  fetchError = '',
  processing,
  handleSubmit,
  valid,
  loginSource,
  anyTouched,
  change,
}) => {
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch();
  const dobError = useSelector<ReduxStore, boolean>(state => {
    // TODO (lair.junior): Verify if syncErrors exists and remove "as any"
    const dobSyncErrors = (state.form.login as any).syncErrors?.dob || {};
    const dobFields = Object.entries(state.form.login.fields?.dob || {})
      .filter(([key, { touched }]) => touched && key in dobSyncErrors)
      .map(([key]) => key);
    return dobFields.length > 0;
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleSubmitClick = async (e: React.ChangeEvent) => {
    e.preventDefault();

    try {
      const recaptchaCode = await recaptchaRef.current?.executeAsync();
      change('recaptchaCode', recaptchaCode);
      handleSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ReCAPTCHA error: ', error);
    } finally {
      change('recaptchaCode', '');
      recaptchaRef.current?.reset();
    }
  };

  useEffect(() => {
    dispatch(
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.CANDIDATE_PORTAL_LOGIN_PAGE_VIEWED,
        {},
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    setDisabledButton(!anyTouched || !valid);
  }, [anyTouched, valid]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <form>
      <Panel>
        <TStyled
          value={`components.${loginSource}.welcomeText`}
          internationalLogin={`${CHECKR_APPLICANTS_EU_BASE_URL}/international`}
          dangerousHTML
        />
      </Panel>
      <LoginErrorNotification
        fetchError={fetchError}
        loginSource={loginSource}
      />
      <PanelStyled i18n title='components.LoginForm.title'>
        <M.Container.Row>
          <InputStyled>
            <Field
              type='tel'
              name='phone'
              label='labels.phone'
              placeholder='placeholders.phone'
              component={Text}
              testId='phone-input'
              validate={[V.required]}
            />
          </InputStyled>

          <InputStyled>
            <Field
              type='email'
              name='email'
              label='labels.email'
              placeholder='placeholders.email'
              component={Text}
              testId='email-input'
              validate={[V.required, V.emailFormat]}
            />
          </InputStyled>
          <InputStyled>
            <Field
              type='tel'
              name='ssn'
              placeholder='placeholders.ssn'
              label='labels.ssn'
              component={SensitiveInput}
              mask={/\w/g}
              normalize={N.ssn}
              testId='ssn-input'
              validate={[V.required, V.ssnValid]}
              disabled={!loaded}
            />
          </InputStyled>
        </M.Container.Row>
        <DobWrapperStyled>
          <DobLabelStyled
            htmlFor='dob-month'
            className={dobError ? 'invalid-feedback' : ''}
          >
            <T value='labels.dob' />
          </DobLabelStyled>
          <M.Container.Row>
            <InputStyled>
              <Field
                type='select'
                name='dob.month'
                component={Select}
                testId='dob-month-select'
                validate={[V.required]}
                options={O.months()}
              />
            </InputStyled>

            <InputStyled>
              <Field
                type='select'
                name='dob.day'
                component={Select}
                testId='dob-day-select'
                validate={[V.required]}
                options={O.days()}
              />
            </InputStyled>

            <InputStyled>
              <Field
                type='select'
                name='dob.year'
                component={Select}
                disabled={processing}
                testId='dob-year-select'
                validate={[V.required]}
                options={O.years(14, 100)}
              />
            </InputStyled>
          </M.Container.Row>
        </DobWrapperStyled>
        <M.Container.Row>
          <EmailAuthLink
            to={PATHS.EMAIL_AUTH}
            data-testid='email-auth-link'
            onClick={() => {
              dispatch(clearFetchReportsFailure());
            }}
          >
            {i18n.getStr('components.LoginForm.redirectToEmailLogin')}
          </EmailAuthLink>
        </M.Container.Row>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          size='invisible'
          badge='inline'
          hl={i18n.getGoogleRecaptchaLocale()}
          onErrored={() => {
            // eslint-disable-next-line no-console
            console.error('ReCAPTCHA error');
          }}
        />
      </PanelStyled>

      <ButtonWrapperStyled>
        <ButtonStyled
          data-testid='get-status-button'
          type='submit'
          onClick={handleSubmitClick}
          disabled={processing || disabledButton}
          kind={`${disabledButton ? 'secondary' : 'primary'}`}
        >
          {processing || !loaded ? (
            <M.LoadingInline
              className={`button-loading-icon ${
                disabledButton ? '' : 'button-loading-icon--primary'
              }`}
            />
          ) : (
            <T value='buttons.getStatus' />
          )}
        </ButtonStyled>
      </ButtonWrapperStyled>
    </form>
  );
};

const LoginReduxForm = reduxForm<LoginFormValues, LoginFormProps>({
  form: LOGIN_FORM_NAME,
})(LoginForm);

LoginForm.propTypes = {
  fetchError: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  loginSource: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  fetchError: '',
};

export default i18n.renderTranslation()(LoginReduxForm);

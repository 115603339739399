import React, { ReactNode } from 'react';
import { i18n } from '@international/mastodon-i18n';
import Typography2024 from './Typography2024Hack';

interface TitledContainerProps {
  titleKey: string;
  children: ReactNode;
}

export const TitledContainer: React.FC<TitledContainerProps> = ({
  titleKey,
  children,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '16px',
      alignSelf: 'stretch',
    }}
  >
    <h5
      style={{
        textAlign: 'left',
        ...Typography2024.h5,
        ...Typography2024.textIconBlackPrimary,
      }}
    >
      {i18n.getStr(titleKey)}
    </h5>
    {children}
  </div>
);

/* eslint-disable consistent-return */
import { VALID_SSN, UNMATCHED_SSN, REQUIRED_SSN } from './messages';
import { validateHasNoSsn } from './helperValidations';

const ssnCorrectFormat = ssn => {
  return /^\d{3}-\d{2}-\d{4}$/.test(ssn);
};

const ssnZeroInAnySection = ssn => {
  return (
    ssn.slice(0, 3) === '000' ||
    ssn.slice(4, 6) === '00' ||
    ssn.slice(7, 11) === '0000'
  );
};

const ssnStartsWith666 = ssn => {
  return ssn.slice(0, 3) === '666';
};

const isITIN = ssn => {
  return ssn.slice(0, 1) === '9';
};

const ssnInBlackList = ssn => {
  const SSN_BLACKLIST = [
    '123-45-6789',
    '219-09-9999',
    '078-05-1120',
    '987-65-4320',
    '987-65-4321',
    '987-65-4322',
    '987-65-4323',
    '987-65-4324',
    '987-65-4325',
    '987-65-4326',
    '987-65-4327',
    '987-65-4328',
    '987-65-4329',
    '111-11-1111',
    '222-22-2222',
    '333-33-3333',
    '444-44-4444',
    '555-55-5555',
    '777-77-7777',
    '888-88-8888',
    '999-99-9999',
    '012-34-5678',
  ];

  return SSN_BLACKLIST.indexOf(ssn) !== -1;
};

const ssnRequired = (value, allValues) => {
  const { noSsn } = allValues;
  if (!value && noSsn !== true) {
    return REQUIRED_SSN;
  }
};

const ssnValid = (ssn, allValues) => {
  const hasNoSsn = validateHasNoSsn(allValues);

  const valid =
    hasNoSsn ||
    (ssnCorrectFormat(ssn) &&
      !ssnZeroInAnySection(ssn) &&
      !ssnStartsWith666(ssn) &&
      !isITIN(ssn) &&
      !ssnInBlackList(ssn));

  if (!valid) {
    return VALID_SSN;
  }
};

const ssnMatch = (_, allValues) => {
  const { ssn, ssnConfirmation } = allValues;
  if (ssnConfirmation && ssn !== ssnConfirmation) {
    return UNMATCHED_SSN;
  }
};

export { ssnRequired, ssnValid, ssnMatch };

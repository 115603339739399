import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import { Greeting } from './Greeting';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Icon } from './Icon';
import { Paragraph } from './Paragraph';
import { SecureDisclosure } from './SecureDisclosure';
import { ReduxFileInput } from './ReduxFileInput';
import FooterButtons from './FooterButtons';

interface DocumentUploadProps {
  company?: string;
  firstName?: string;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
  company = '',
  firstName = '',
}) => {
  return (
    <M.Container style={{ background: colors.uiGrey50 }}>
      <Greeting company={company} firstName={firstName} />
      <StyleableContainer type={Type.Info} styledTopBorder>
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.DocumentUpload.uploadHeading'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%', margin: '8px 0px 24px 0px' }} />
        <Paragraph
          textKey='components.InstantExceptions.DocumentUpload.uploadDetail'
          align={HeadingAlignment.Left}
        />
        <Field name='idDocument' component={ReduxFileInput} type='hidden' />
        <Heading
          textKey='components.InstantExceptions.DocumentUpload.acceptedHeader'
          align={HeadingAlignment.Left}
        />
        <M.UnorderedList>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted1'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted2'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted3'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted4'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted5'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted6'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted7'
            />
          </M.ListItem>
        </M.UnorderedList>
        <M.Divider style={{ width: '100%', margin: '16px 0px 16px 0px' }} />
        <SecureDisclosure />
      </StyleableContainer>
      <FooterButtons>
        <M.Button kind='secondary'>{i18n.getStr('buttons.addLater')}</M.Button>
        <M.Button type='submit'>{i18n.getStr('buttons.submit')}</M.Button>
      </FooterButtons>
    </M.Container>
  );
};

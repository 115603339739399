import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { uuid4 } from '@sentry/utils';
import Panel from '../../../../Panels';
import { InternationalCreditObject } from '../../../../../types/report';
import InternationalCreditReportRecord from './InternationalCreditReportRecord';
import InternationalCreditReportEmptyRecord from './InternationalCreditReportEmptyRecord';

interface InternationalCreditProps {
  search: InternationalCreditObject;
}

const InternationalCreditReport = ({ search }: InternationalCreditProps) => {
  const internationalCreditRecords = search?.records?.filter(
    (creditRecord: { countryName: string; pdfUrl: string }) =>
      creditRecord.pdfUrl,
  );

  const internationalCreditReportEmptyRecords = search?.records?.filter(
    (creditRecord: { countryName: string; pdfUrl: string }) =>
      creditRecord.countryName !== 'United States',
  );

  return (
    !!internationalCreditReportEmptyRecords &&
    internationalCreditReportEmptyRecords.length > 0 && (
      <Panel
        data-testid='international-credit-report'
        title={i18n.getStr('components.internationalCreditReport')}
        status={search.status}
      >
        {internationalCreditRecords?.map((record, i) => (
          <InternationalCreditReportRecord
            search={search}
            record={record}
            lastLine={i === search.records.length - 1}
            key={uuid4()}
          />
        ))}
        {internationalCreditRecords?.length === 0 &&
          internationalCreditReportEmptyRecords?.map((emptyRecord, i) => (
            <InternationalCreditReportEmptyRecord
              record={emptyRecord}
              key={uuid4()}
            />
          ))}
      </Panel>
    )
  );
};

InternationalCreditReport.propTypes = {
  search: PropTypes.object,
};

InternationalCreditReport.defaultProps = {
  search: {} as object,
};

export default i18n.renderTranslation()(InternationalCreditReport);
